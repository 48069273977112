import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faSyncAlt,
  faClipboard,
  faCheck,
  faSignOutAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebaseConfig";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  query,
  where,
  serverTimestamp,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import axios from "axios";
import Papa from "papaparse";

const CACHE_KEY = "cachedUrls";
const CACHE_TIMESTAMP_KEY = "cachedUrlsTimestamp";
const CACHE_DURATION = 3600000; // 1 hour

const SCAN_LIMIT = {
  "non-admin": { maxPerSiteInterval: 30000, maxSitesPerHour: 100 }, // 30 seconds
  abuser: { maxPerSiteInterval: 10800000, maxSitesPerHour: 15 }, // 3 hours
  admin: { maxPerSiteInterval: null, maxSitesPerHour: null }, // No limitations
};

const PAGE_LIMIT = 20;

const StoreItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #10253a;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #fff;
`;

const StoreNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StoreName = styled.span`
  font-weight: bold;
`;

const StoreUrl = styled.span`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MainContent = ({ user, setUser, setIsAuthenticated }) => {
  const [url, setUrl] = useState("");
  const [storeName, setStoreName] = useState("");
  const [totalProducts, setTotalProducts] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showNonFreeItems, setShowNonFreeItems] = useState(false);
  const [validUrls, setValidUrls] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [gridItems, setGridItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isCooldown, setIsCooldown] = useState(false); // Cooldown state
  const [sortByPrice, setSortByPrice] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [showUnavailableProducts, setShowUnavailableProducts] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [batchImportPopup, setBatchImportPopup] = useState(false);
  const [batchImportUrls, setBatchImportUrls] = useState("");
  const [importStatusMessage, setImportStatusMessage] = useState("");
  const [lastScans, setLastScans] = useState({});
  const [favouritesPopup, setFavouritesPopup] = useState(false);
  const [favourites, setFavourites] = useState([]);
  const [favouritesSearchTerm, setFavouritesSearchTerm] = useState("");
  const itemsPerPage = 20;
  const [currentFavouritesPage, setCurrentFavouritesPage] = useState(1);
  const favouritesPerPage = 10;
  const [showOnlyFavourites, setShowOnlyFavourites] = useState(false);

  useEffect(() => {
    const fetchUrls = async () => {
      const cachedUrls = JSON.parse(localStorage.getItem(CACHE_KEY));
      const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);

      if (
        cachedUrls &&
        cachedTimestamp &&
        Date.now() - cachedTimestamp < CACHE_DURATION
      ) {
        setValidUrls(cachedUrls);
        console.log("Loaded URLs from cache.");
      } else {
        console.log("Fetching URLs from Firestore...");
        const urlCollection = await getDocs(collection(db, "urls"));
        const urls = urlCollection.docs.map((doc) => doc.data());
        setValidUrls(urls.sort((a, b) => a.name.localeCompare(b.name)));
        console.log("URLs fetched successfully");

        localStorage.setItem(CACHE_KEY, JSON.stringify(urls));
        localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now().toString());
      }
    };
    fetchUrls();
  }, []);

  useEffect(() => {
    const fetchFavourites = async () => {
      if (user && user.favourites) {
        setFavourites(user.favourites);
      }
    };
    fetchFavourites();
  }, [user]);

  const handleUrlSubmit = async (e) => {
    e.preventDefault();
    console.log(`Sending request to ${url}/meta.json`);
    try {
      const response = await fetch(`${url}/meta.json`);
      const data = await response.json();
      console.log("Data fetched from URL:", data);
      if (data.myshopify_domain) {
        const newUrl = {
          url,
          name: data.name,
          addedBy: user.username,
          addedAt: serverTimestamp(),
        };
        if (validUrls.some((item) => item.url === newUrl.url)) {
          alert("This URL is already in the database.");
        } else {
          const updatedUrls = [...validUrls, newUrl].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setValidUrls(updatedUrls);
          await addDoc(collection(db, "urls"), newUrl);
          alert(
            "Store is supported and has been added to the database! You can now select it from the dropdown and scan for freebies!"
          );
          console.log(`URL added successfully: ${url}`);

          localStorage.setItem(CACHE_KEY, JSON.stringify(updatedUrls));
          localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now().toString());

          // Automatically set the selected URL and scan it
          setSelectedUrl(url);
          handleScan();

          try {
            console.log("Attempting to send Discord webhook for new store...");
            const embedPayload = {
              content: null,
              embeds: [
                {
                  title: data.name,
                  description: "`NEW STORE ADDED TO DATABASE!`",
                  url: url,
                  color: 4778794,
                  fields: [
                    {
                      name: "TOTAL PRODUCTS",
                      value: data.published_products_count.toString(),
                      inline: true,
                    },
                    {
                      name: "URL",
                      value: url,
                    },
                    {
                      name: "ADDED BY",
                      value: user.username,
                    },
                  ],
                },
              ],
              username: "DATABASE UPDATE",
              attachments: [],
            };

            // Send to the existing webhook
            await axios.post(
              "https://discord.com/api/webhooks/1266350402924711989/Dv-4qAq-BzijpsYDj1jSlUdo_RrLq46g4hcYaocWmuMY3yUSLonpmTPSZkHRrhKclclw",
              embedPayload
            );

            // Send to the additional webhook
            await axios.post(
              "https://discord.com/api/webhooks/1339961836476563477/mFFfpNB2TSqERnhoTN8QM5ZKR2qP-H3MjpaBCqWhofzabpb2OOEdhxj-cfs_r41cRxJ1",
              embedPayload
            );

            console.log("Discord webhooks for new store sent successfully");
          } catch (webhookError) {
            console.error(
              "Failed to send Discord webhook for new store",
              webhookError
            );
          }
        }
      } else {
        alert("URL provided is not Shopify");
      }
    } catch (error) {
      console.error("Failed to fetch data from the URL", error);
      alert("Failed to fetch data from the URL");
    }
  };

  const handleDeleteUrl = async () => {
    if (user.role !== "admin") {
      alert("You do not have permission to remove sites.");
      return;
    }

    if (!selectedUrl) {
      alert("Please select a URL to remove.");
      return;
    }

    try {
      const q = query(collection(db, "urls"), where("url", "==", selectedUrl));
      const urlToDelete = await getDocs(q);
      urlToDelete.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      const updatedUrls = validUrls.filter((item) => item.url !== selectedUrl);
      setValidUrls(updatedUrls);
      localStorage.setItem(CACHE_KEY, JSON.stringify(updatedUrls));
      alert("URL removed successfully.");
    } catch (error) {
      console.error("Failed to remove URL", error);
      alert("Failed to remove URL");
    }
  };

  // Add this new function to handle sending webhooks
  const sendWebhookForFreeProduct = async (item, selectedUrl, user) => {
    const freeVariants = item.variants.filter((v) => Number(v.price) === 0);

    if (freeVariants.length > 0) {
      try {
        console.log(`Sending webhook for product: ${item.title}`);
        const productEmbedPayload = {
          content: null,
          embeds: [
            {
              title: `${item.title}`,
              url: `${selectedUrl}/products/${item.handle}`,
              color: 4778794,
              fields: [
                {
                  name: "Variant IDs",
                  value: freeVariants.map((v) => v.id).join(", "),
                  inline: true,
                },
                {
                  name: "ATC",
                  value: `[ATC 1](${selectedUrl}/cart/${freeVariants
                    .map((v) => `${v.id}:1`)
                    .join(",")}) | [ATC 5](${selectedUrl}/cart/${freeVariants
                    .map((v) => `${v.id}:5`)
                    .join(",")}) | [ATC 10](${selectedUrl}/cart/${freeVariants
                    .map((v) => `${v.id}:10`)
                    .join(",")})`,
                  inline: false,
                },
                {
                  name: "Scanned by",
                  value: user.username,
                  inline: true,
                },
              ],
              thumbnail: { url: item.thumbnail },
            },
          ],
          username: "PRODUCT UPDATE",
          attachments: [],
        };

        // Send to the existing webhook
        await axios.post(
          "https://discord.com/api/webhooks/1267947263309905984/Q6Qe19qK8LHwD-oHh01Qqfdn-39rAZ0LngtlW3w8YWkCaOeMCOlBc_BkTyfSMnzw7wsX",
          productEmbedPayload
        );

        // Send to the additional webhook
        await axios.post(
          "https://discord.com/api/webhooks/1339961874749591552/M-e3qmw9-maekrk3kKrCMJ7H05F9Tl8HTbF9IanmA7D3X-tdOJiDSs-kp_QWtdIQ2CWs",
          productEmbedPayload
        );

        console.log("Webhook for free product sent successfully");
      } catch (webhookError) {
        console.error("Failed to send webhook for free product", webhookError);
      }
    }
  };

  const handleScan = async () => {
    if (!selectedUrl) {
      alert("Please select a URL to scan.");
      return;
    }

    if (isCooldown) {
      alert("1 second cooldown initiated. Please try again shortly.");
      return;
    }

    const userRole = user.role || "non-admin";
    const { maxPerSiteInterval, maxSitesPerHour } =
      SCAN_LIMIT[userRole] || SCAN_LIMIT["non-admin"];
    const currentTime = new Date().getTime();

    if (userRole !== "admin") {
      // Check for cooldown
      if (
        lastScans[selectedUrl] &&
        currentTime - lastScans[selectedUrl].lastScanTime < maxPerSiteInterval
      ) {
        alert(
          `You can only scan this site once every ${
            maxPerSiteInterval / 1000
          } second(s).`
        );
        return;
      }

      // Check for hourly limit
      const sitesScannedInLastHour = Object.values(lastScans).filter(
        (scan) => currentTime - scan.lastScanTime < 3600000
      ).length;
      if (sitesScannedInLastHour >= maxSitesPerHour) {
        alert(`You can only scan up to ${maxSitesPerHour} websites per hour.`);
        return;
      }
    } else {
      console.log("Admin user: no scan limits applied.");
    }

    console.log(`Scanning ${selectedUrl}`);
    setGridItems([]);
    setIsCooldown(true); // Start cooldown
    setStoreName("");
    setTotalProducts(null);
    setStatusMessage("");

    let page = 1;
    let allItems = [];
    let hasMoreProducts = true;

    try {
      const metaResponse = await fetch(`${selectedUrl}/meta.json`);
      const metaData = await metaResponse.json();
      setStoreName(metaData.name);
      setTotalProducts(metaData.published_products_count);
      setStatusMessage(`Checking ${metaData.name}: page ${page}`);
    } catch (error) {
      console.error("Failed to fetch store metadata", error);
      alert("Failed to fetch store metadata");
      setIsCooldown(false);
      return;
    }

    // Continue scanning even if totalProducts exceeds a high number, but limit to PAGE_LIMIT pages
    while (hasMoreProducts && page <= PAGE_LIMIT) {
      try {
        const response = await fetch(
          `${selectedUrl}/products.json?limit=250&page=${page}`
        );
        const data = await response.json();
        if (data.products.length === 0) {
          hasMoreProducts = false;
        } else {
          const newItems = data.products.map((product) => {
            const freeVariants = product.variants.filter(
              (variant) => variant.price === "0.00"
            );
            const allVariants = product.variants.map((variant) => ({
              id: variant.id,
              available: variant.available,
              price: parseFloat(variant.price),
              title: variant.title,
            }));
            return {
              title: product.title,
              handle: product.handle,
              thumbnail: product.images[0] ? product.images[0].src : "",
              variants: showNonFreeItems ? allVariants : freeVariants,
            };
          });
          const filteredItems = newItems.filter(
            (item) =>
              item.variants.length > 0 &&
              (showUnavailableProducts ||
                item.variants.some((variant) => variant.available))
          );
          allItems = [...allItems, ...filteredItems];
          page++;
          setStatusMessage(`Checking ${storeName}: page ${page}`);

          filteredItems.forEach((item, index) => {
            setTimeout(async () => {
              await sendWebhookForFreeProduct(item, selectedUrl, user);
            }, index * 5000); // 5 seconds delay between webhooks
          });
        }
      } catch (error) {
        console.error("Failed to fetch products", error);
        alert("Failed to fetch products");
        hasMoreProducts = false;
      }
    }

    if (sortByPrice) {
      allItems.sort(
        (a, b) =>
          Math.min(...a.variants.map((v) => v.price)) -
          Math.min(...b.variants.map((v) => v.price))
      );
    }

    setGridItems(allItems);
    setCurrentPage(1);
    setTimeout(() => setIsCooldown(false), 1000);
    setStatusMessage("");

    setLastScans((prevScans) => ({
      ...prevScans,
      [selectedUrl]: { lastScanTime: currentTime, user: user.username },
    }));
  };

  const handleAddAllToCart = () => {
    const allAvailableVariants = gridItems
      .flatMap((item) => item.variants)
      .filter((variant) => variant.available)
      .map((variant) => `${variant.id}:${quantity}`)
      .join(",");
    if (allAvailableVariants) {
      window.open(`${selectedUrl}/cart/${allAvailableVariants}`, "_blank");
    } else {
      alert("No available variants to add to cart.");
    }
  };

  const handleAddSelectedToCart = () => {
    const selectedVariantIds = selectedVariants
      .map((variantId) =>
        gridItems.flatMap((item) =>
          item.variants.filter((variant) => variant.id === variantId)
        )
      )
      .flat()
      .filter((variant) => variant.available)
      .map((variant) => `${variant.id}:${quantity}`)
      .join(",");
    if (selectedVariantIds) {
      window.open(`${selectedUrl}/cart/${selectedVariantIds}`, "_blank");
    } else {
      alert("No selected variants to add to cart.");
    }
  };

  const handleCopySelectedVariants = () => {
    if (selectedVariants.length === 0) {
      alert("No selected variants to copy.");
      return;
    }

    const variantIds = selectedVariants
      .map((variantId) =>
        gridItems.flatMap((item) =>
          item.variants.filter((variant) => variant.id === variantId)
        )
      )
      .flat()
      .map((variant) => `${variant.id}:${quantity}`)
      .join(",");

    const selectedVariantDetails = `${selectedUrl}/cart/${variantIds}`;

    navigator.clipboard.writeText(selectedVariantDetails).then(() => {
      alert("Selected variants copied to clipboard.");
      console.log(`Copied to clipboard: ${selectedVariantDetails}`);
    });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log(`Copied to clipboard: ${text}`);
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleLogout = async () => {
    try {
      if (user && user.uid) {
        const userDocRef = doc(db, "users", user.uid);
        await setDoc(userDocRef, { isLoggedOut: true }, { merge: true });
        console.log("User logged out and database updated.");
        window.location.href = "/";
      }

      setUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem("user");

      window.location.href = "/";
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleToggleChange = (toggleFunction) => {
    toggleFunction((prevState) => !prevState);
    alert("Please scan the store again to apply your filters.");
  };

  const handleVariantSelect = (variantId) => {
    setSelectedVariants((prevSelected) =>
      prevSelected.includes(variantId)
        ? prevSelected.filter((id) => id !== variantId)
        : [...prevSelected, variantId]
    );
  };

  const handleBatchImportSubmit = async () => {
    // Commenting out the role restriction for batch import
    if (user.role === "abuser") {
      alert("Users with 'abuser' role cannot use the batch import feature.");
      return;
    }

    const urls = batchImportUrls.trim().split("\n").filter(Boolean);
    // if (urls.length > 50) {
    //   alert("You can only import a maximum of 50 URLs at once.");
    //   return;
    // }
    setBatchImportPopup(false);
    alert(
      "Please do not close OR interact with this page. You can close this popup but do not do anything else. There is a 1.5s cooldown PER store in place. Be patient. Have a coffee. Read a book. Your import will begin shortly."
    );

    let addedCount = 0;
    for (const importUrl of urls) {
      try {
        setImportStatusMessage(`Validating URL: ${importUrl}`);
        const response = await fetch(`${importUrl}/meta.json`);
        const data = await response.json();
        if (data.myshopify_domain) {
          const newUrl = {
            url: importUrl,
            name: data.name,
            addedBy: user.username,
          };
          if (!validUrls.some((item) => item.url === newUrl.url)) {
            setValidUrls((prevUrls) =>
              [...prevUrls, newUrl].sort((a, b) => a.name.localeCompare(b.name))
            );
            await addDoc(collection(db, "urls"), newUrl);
            addedCount++;
          }
        }
      } catch (error) {
        console.error("Failed to fetch data from the URL", error);
      }
      await new Promise((resolve) => setTimeout(resolve, 1500)); // 1.5 seconds cooldown
    }
    setImportStatusMessage("");
    alert(
      `Mass import completed. ${addedCount} stores successfully added to the database. You may now select the store from the dropdown to initiate a scan.`
    );
  };

  const handleBatchImportCSV = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const urls = results.data.map((row) => row.url).filter(Boolean);
        if (urls.length === 0) {
          alert("No valid URLs found in the CSV file.");
          return;
        }

        alert(
          "Please do not close OR interact with this page. You can close this popup but do not do anything else. There is a 1.5s cooldown PER store in place. Be patient. Have a coffee. Read a book. Your import will begin shortly."
        );

        let addedCount = 0;
        for (const importUrl of urls) {
          try {
            setImportStatusMessage(`Validating URL: ${importUrl}`);
            const response = await fetch(`${importUrl}/meta.json`);
            const data = await response.json();
            if (data.myshopify_domain) {
              const newUrl = {
                url: importUrl,
                name: data.name,
                addedBy: user.username,
              };
              if (!validUrls.some((item) => item.url === newUrl.url)) {
                setValidUrls((prevUrls) =>
                  [...prevUrls, newUrl].sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )
                );
                await addDoc(collection(db, "urls"), newUrl);
                addedCount++;
              }
            }
          } catch (error) {
            console.error("Failed to fetch data from the URL", error);
          }
          await new Promise((resolve) => setTimeout(resolve, 1500)); // 1.5 seconds cooldown
        }
        setImportStatusMessage("");
        alert(
          `CSV import completed. ${addedCount} stores successfully added to the database. You may now select the store from the dropdown to initiate a scan.`
        );
      },
    });
  };

  const handleRemoveFromFavourites = async (url) => {
    try {
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        favourites: arrayRemove(url),
      });
      setFavourites((prev) => prev.filter((fav) => fav !== url));
      alert("URL removed from favourites.");
    } catch (error) {
      console.error("Failed to remove URL from favourites", error);
      alert("Failed to remove URL from favourites");
    }
  };

  const handleScanAllFavourites = async () => {
    if (favourites.length === 0) {
      alert("No favourites to scan.");
      return;
    }

    alert(
      "Scanning all favourites. Please do not close or interact with this page."
    );

    let combinedItems = [];
    for (const favUrl of favourites) {
      setSelectedUrl(favUrl);
      const items = await scanUrl(favUrl); // Use a helper function to scan each URL
      combinedItems = [...combinedItems, ...items];

      // Send webhook for each freebie found
      items.forEach(async (item) => {
        await sendWebhookForFreeProduct(item, favUrl, user);
      });

      await new Promise((resolve) => setTimeout(resolve, 1500)); // 1.5 seconds cooldown
    }

    setGridItems(combinedItems);
    setCurrentPage(1);
  };

  // Helper function to scan a single URL
  const scanUrl = async (url) => {
    try {
      const response = await fetch(`${url}/products.json?limit=250`);
      const data = await response.json();
      return data.products.map((product) => ({
        title: product.title,
        handle: product.handle,
        thumbnail: product.images[0] ? product.images[0].src : "",
        variants: product.variants.map((variant) => ({
          id: variant.id,
          available: variant.available,
          price: parseFloat(variant.price),
          title: variant.title,
        })),
      }));
    } catch (error) {
      console.error(`Failed to scan ${url}`, error);
      return [];
    }
  };

  const handleRemoveAllFavourites = async () => {
    try {
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        favourites: [],
      });
      setFavourites([]);
      alert("All favourites removed.");
    } catch (error) {
      console.error("Failed to remove all favourites", error);
      alert("Failed to remove all favourites");
    }
  };

  const handleToggleFavouritesFilter = () => {
    setShowOnlyFavourites((prev) => !prev);
  };

  // Define filteredUrls before using it
  const filteredUrls = validUrls.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredFavourites = showOnlyFavourites
    ? validUrls.filter((item) => favourites.includes(item.url))
    : validUrls
        .filter((item) =>
          item.name.toLowerCase().includes(favouritesSearchTerm.toLowerCase())
        )
        .slice(
          (currentFavouritesPage - 1) * favouritesPerPage,
          currentFavouritesPage * favouritesPerPage
        );

  const displayedItems = gridItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const recentlyAddedUrls = validUrls
    .filter((item) => {
      const oneHourAgo = new Date().getTime() - 3600000; // 1 hour in milliseconds
      return (
        item.addedAt &&
        typeof item.addedAt.toMillis === "function" &&
        item.addedAt.toMillis() >= oneHourAgo
      );
    })
    .sort((a, b) => {
      if (
        typeof b.addedAt.toMillis === "function" &&
        typeof a.addedAt.toMillis === "function"
      ) {
        return b.addedAt.toMillis() - a.addedAt.toMillis();
      }
      return 0; // Default case if either addedAt is not a timestamp
    })
    .slice(0, 20); // Limit to 20 entries

  const handleAddToFavourites = async (url) => {
    try {
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        favourites: arrayUnion(url),
      });
      setFavourites((prev) => [...prev, url]);
      alert("URL added to favourites.");
    } catch (error) {
      console.error("Failed to add URL to favourites", error);
      alert("Failed to add URL to favourites");
    }
  };

  return (
    <MainContentContainer>
      <Header>
        <TitleContainer>
          <Title>Shopify Product Scraper [Beta - Invite only]</Title>
          <Subtitle>
            Scrapes user-defined Shopify retailers for a list of products
            including variants and availability for personal usage, FBA/FBM
            sellers, and market research purposes. Please use this tool
            responsibly or your access will be revoked. Validating a new store
            will automatically add it to the global database for all users to
            access from the dropdown menu.
          </Subtitle>
        </TitleContainer>
        <div>
          <UserGreeting>Not {user.username}?</UserGreeting>
          <LogoutButton onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
          </LogoutButton>
        </div>
      </Header>

      <FormContainer>
        <UrlForm onSubmit={handleUrlSubmit}>
          <UrlInput
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter URL"
          />
          <SearchButton type="submit">
            <FontAwesomeIcon icon={faCheck} /> Validate
          </SearchButton>
        </UrlForm>
        <DropdownContainer>
          <SearchInput
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search in URLs"
          />
          <Dropdown
            value={selectedUrl}
            onChange={(e) => setSelectedUrl(e.target.value)}
          >
            <option value="">Select a URL</option>
            {recentlyAddedUrls.length > 0 && (
              <>
                <option disabled>RECENTLY ADDED</option>
                {recentlyAddedUrls.map((item, index) => (
                  <option key={index} value={item.url}>
                    {item.name} ({item.url})
                  </option>
                ))}
                <option disabled>──────────────────</option>
              </>
            )}
            {filteredUrls.map((item, index) => (
              <option key={index} value={item.url}>
                {item.name} ({item.url})
              </option>
            ))}
          </Dropdown>
          <ButtonGroup>
            <ScanButton type="button" onClick={handleScan}>
              <FontAwesomeIcon icon={faSyncAlt} /> Scan
            </ScanButton>
            <RemoveButton type="button" onClick={handleDeleteUrl}>
              <FontAwesomeIcon icon={faTrash} /> Remove
            </RemoveButton>
            <FavouritesButton
              type="button"
              onClick={() => setFavouritesPopup(true)}
            >
              FAVOURITES
            </FavouritesButton>
          </ButtonGroup>
        </DropdownContainer>
      </FormContainer>
      <ToggleContainer>
        <input
          type="checkbox"
          checked={showNonFreeItems}
          onChange={() => handleToggleChange(setShowNonFreeItems)}
        />
        <ToggleLabel>SHOW PAID PRODUCTS | </ToggleLabel>
        <input
          type="checkbox"
          checked={sortByPrice}
          onChange={() => handleToggleChange(setSortByPrice)}
        />
        <ToggleLabel>SORT BY LOWEST PRICE | </ToggleLabel>
        <input
          type="checkbox"
          checked={showUnavailableProducts}
          onChange={() => handleToggleChange(setShowUnavailableProducts)}
        />
        <ToggleLabel>SHOW UNAVAILABLE PRODUCTS</ToggleLabel>
      </ToggleContainer>
      <QuantityContainer>
        <div style={{ color: "#ffffff", marginBottom: "5px" }}>
          DEFAULT QUANTITY:
        </div>
        <QuantityInput
          type="number"
          min="1"
          max="100"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </QuantityContainer>
      <ButtonsRow>
        <AddSelectedButtonContainer>
          <AddSelectedButton type="button" onClick={handleAddSelectedToCart}>
            <FontAwesomeIcon icon={faCheck} /> ADD SELECTED VARIANTS TO CART
          </AddSelectedButton>
          <CopySelectedButton onClick={handleCopySelectedVariants}>
            <FontAwesomeIcon icon={faClipboard} /> COPY SELECTED VARIANTS LINK
          </CopySelectedButton>
        </AddSelectedButtonContainer>
        <AddAllButton type="button" onClick={handleAddAllToCart}>
          <FontAwesomeIcon icon={faCheck} /> ADD ALL AVAILABLE VARIANTS TO CART
        </AddAllButton>
        <BatchImportButton onClick={() => setBatchImportPopup(true)}>
          BATCH IMPORT URL
        </BatchImportButton>
        <BatchImportButton as="label">
          BATCH IMPORT VIA CSV
          <input
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleBatchImportCSV}
          />
        </BatchImportButton>
      </ButtonsRow>
      {batchImportPopup && (
        <PopupContainer>
          <PopupContent>
            <PopupHeader>
              <h3>BATCH IMPORT URL</h3>
              <CloseButton onClick={() => setBatchImportPopup(false)}>
                X
              </CloseButton>
            </PopupHeader>
            <PopupBody>
              <textarea
                placeholder="Paste URLs here, one per line."
                value={batchImportUrls}
                onChange={(e) => setBatchImportUrls(e.target.value)}
                rows="10"
                style={{
                  width: "100%",
                  borderRadius: "15px",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
              ></textarea>
              <SubmitButton onClick={handleBatchImportSubmit}>
                Submit
              </SubmitButton>
            </PopupBody>
          </PopupContent>
        </PopupContainer>
      )}
      {favouritesPopup && (
        <PopupContainer>
          <PopupContent>
            <PopupHeader>
              <h3>Manage Favourites</h3>
              <CloseButton onClick={() => setFavouritesPopup(false)}>
                X
              </CloseButton>
            </PopupHeader>
            <PopupBody>
              <ButtonRow>
                <SubmitButton onClick={handleScanAllFavourites}>
                  Scan All Favourites
                </SubmitButton>
                <RemoveAllButton onClick={handleRemoveAllFavourites}>
                  Remove All Favourites
                </RemoveAllButton>
              </ButtonRow>
              <FavouritesSearchInput
                type="text"
                placeholder="Search URLs"
                value={favouritesSearchTerm}
                onChange={(e) => {
                  setFavouritesSearchTerm(e.target.value);
                  setCurrentFavouritesPage(1); // Reset to page 1
                }}
              />
              <ToggleContainer>
                <input
                  type="checkbox"
                  checked={showOnlyFavourites}
                  onChange={handleToggleFavouritesFilter}
                />
                <ToggleLabel>Show Only Favourites</ToggleLabel>
              </ToggleContainer>
              <div>
                {filteredFavourites.map((item, index) => (
                  <StoreItem key={index}>
                    <StoreNameContainer>
                      <StoreName>{item.name}</StoreName>
                      <StoreUrl>({item.url})</StoreUrl>
                    </StoreNameContainer>
                    <IconContainer>
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{
                          color: favourites.includes(item.url)
                            ? "gold"
                            : "gray",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          favourites.includes(item.url)
                            ? handleRemoveFromFavourites(item.url)
                            : handleAddToFavourites(item.url)
                        }
                      />
                    </IconContainer>
                  </StoreItem>
                ))}
              </div>
              <PaginationContainer>
                <PaginationButton
                  onClick={() =>
                    setCurrentFavouritesPage(currentFavouritesPage - 1)
                  }
                  disabled={currentFavouritesPage === 1}
                >
                  Previous
                </PaginationButton>
                <PaginationButton
                  onClick={() =>
                    setCurrentFavouritesPage(currentFavouritesPage + 1)
                  }
                  disabled={
                    currentFavouritesPage ===
                    Math.ceil(validUrls.length / favouritesPerPage)
                  }
                >
                  Next
                </PaginationButton>
              </PaginationContainer>
            </PopupBody>
          </PopupContent>
        </PopupContainer>
      )}
      <StatusMessage>
        {statusMessage || importStatusMessage}
        {statusMessage && (
          <>
            <br />
            Total products found: {totalProducts}
          </>
        )}
      </StatusMessage>
      <GridContainer>
        {displayedItems.length === 0 ? (
          <GridPlaceholder>
            {importStatusMessage ? (
              <span>{importStatusMessage}</span>
            ) : (
              <span>🛒 No items found. Start by entering a URL above.</span>
            )}
          </GridPlaceholder>
        ) : (
          displayedItems.map((item, index) => (
            <GridItem key={index}>
              <GridThumbnail src={item.thumbnail} alt={item.title} />
              <GridTitle>
                <a
                  href={`${selectedUrl}/products/${item.handle}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.title}
                </a>
              </GridTitle>
              <VariantsContainer>
                {item.variants.map((variant, vIndex) => (
                  <VariantStatus key={vIndex} available={variant.available}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <span>{variant.title}</span>
                      <span>(PRICE: {variant.price})</span>
                      <input
                        type="checkbox"
                        onChange={() => handleVariantSelect(variant.id)}
                        checked={selectedVariants.includes(variant.id)}
                      />
                    </div>
                    <CopyIcon
                      icon={faClipboard}
                      onClick={() => handleCopy(variant.id)}
                    />
                  </VariantStatus>
                ))}
              </VariantsContainer>

              <div>
                <ActionButton>
                  <a
                    href={`${selectedUrl}/cart/${item.variants
                      .map((v) => `${v.id}:${quantity}`)
                      .join(",")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ATC ALL
                  </a>
                </ActionButton>
                <CopyAllButton
                  onClick={() =>
                    handleCopy(
                      item.variants.map((v) => `${v.id}:${quantity}`).join(",")
                    )
                  }
                >
                  COPY ALL
                </CopyAllButton>
                <CartButton>
                  <a
                    href={`${selectedUrl}/cart`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CART
                  </a>
                </CartButton>
              </div>
            </GridItem>
          ))
        )}
      </GridContainer>
      {gridItems.length > itemsPerPage && (
        <PaginationContainer>
          <PaginationButton
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </PaginationButton>
          <PaginationButton
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(gridItems.length / itemsPerPage)
            }
          >
            Next
          </PaginationButton>
        </PaginationContainer>
      )}
    </MainContentContainer>
  );
};

const MainContentContainer = styled.div`
  padding: 20px 40px;
  color: #fff;
  background-color: #031424;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 20px 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TitleContainer = styled.div`
  text-align: left;
`;

const Title = styled.h1`
  font-size: 32px;
  color: #bb86fc;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.h2`
  font-size: 12px;
  color: #ffffff;
  opacity: 0.7;
  margin: 5px 0 0 0;
  font-weight: 400;
`;

const LogoutButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: #cf6679;
  color: #121212;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #b00020;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const UrlForm = styled.form`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const UrlInput = styled.input`
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: #10253a;
  color: #fff;
  &:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(187, 134, 252, 0.5);
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: #bb86fc;
  color: #121212;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #3700b3;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: #10253a;
  color: #fff;
  &:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(187, 134, 252, 0.5);
  }
`;

const Dropdown = styled.select`
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: #10253a;
  color: #fff;
  max-height: 200px;
  overflow-y: auto;
  flex: 1;
  &:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(187, 134, 252, 0.5);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ScanButton = styled(SearchButton)`
  background-color: #03dac6;
  &:hover {
    background-color: #018786;
  }
`;

const RemoveButton = styled(SearchButton)`
  background-color: #cf6679;
  &:hover {
    background-color: #b00020;
  }
`;

const FavouritesButton = styled.button`
  background-color: #ff6347;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background-color: #ff4500;
  }
`;

const AddAllButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background-color: #fbc02d;
  }
`;

const AddSelectedButton = styled.button`
  background-color: #ffeb3b;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background-color: #fbc02d;
  }
`;

const AddSelectedButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CopySelectedButton = styled.button`
  background-color: #03dac6;
  color: #121212;
  padding: 10px;
  margin-left: 10px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #018786;
  }
`;

const BatchImportButton = styled.button`
  background-color: #ff6347;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 10px;
  width: auto;
  &:hover {
    background-color: #ff4500;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

const QuantityContainer = styled.div`
  margin-top: 10px;
`;

const QuantityInput = styled.input`
  width: 60px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: #10253a;
  color: #fff;
  &:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(187, 134, 252, 0.5);
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: #031424;
  padding: 20px;
  border-radius: 15px;
  width: 600px;
  height: 75vh;
  overflow-y: auto;
  z-index: 1000;
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: white;
`;

const PopupBody = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #fbc02d;
  }
`;

const RemoveAllButton = styled(SubmitButton)`
  background-color: #ff7f7f; // Pastel red
  &:hover {
    background-color: #ff4c4c;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ToggleLabel = styled.label`
  margin-left: 5px;
  font-size: 14px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  min-width: 350px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }
`;

const GridPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
  font-size: 18px;
  text-align: center;
  width: 100%;
  height: 100%;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
`;

const GridThumbnail = styled.img`
  width: 100%;
  height: auto;
  border-radius: 15px;
`;

const GridTitle = styled.h3`
  color: #bb86fc;
  margin: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 15px;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const VariantsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const VariantStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #031424;
  background-color: ${(props) => (props.available ? "#00c853" : "#d50000")};
  border-radius: 15px;
  padding: 5px 10px;
  margin-bottom: 5px;
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const GridItem = styled.div`
  background-color: #071a2b;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  max-height: 600px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    padding: 15px;
    min-height: 350px;
    max-height: 350px;
  }
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  border-radius: 15px;
  margin: 5px;
  background-color: #bb86fc;
  color: #121212;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #3700b3;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const CartButton = styled(ActionButton)`
  background-color: #f5a623;
  &:hover {
    background-color: #d48800;
  }
`;

const CopyAllButton = styled(ActionButton)`
  background-color: #03dac6;
  &:hover {
    background-color: #018786;
  }
`;

const CopyIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.6);
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  border-radius: 15px;
  background-color: #bb86fc;
  color: #121212;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #3700b3;
  }
  &:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
`;

const StatusMessage = styled.div`
  color: #bb86fc;
  text-align: center;
  margin-top: 20px;
`;

const UserGreeting = styled.span`
  color: #ffffff;
  margin-right: 10px;
  font-size: 16px;
`;

const FavouritesSearchInput = styled.input`
  width: 100%;
  max-width: 500px;
  border-radius: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  z-index: 1001;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export default MainContent;
